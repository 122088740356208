footer{
    background: var(--blue);
    padding: 30px 50px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
/* footer .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    border-bottom: 2px solid white;
} */
/* footer .contact{
    display: flex;
    align-items: center;
} */
/* footer .contact div{
    display: flex;
    gap: 100px;
    margin-top: 10px;
} */
footer img{
    width: 120px;
}
/* footer .privacy{
    text-align: center;
    margin-top: 30px;
} */
footer a{
    color: white;
    transition: .4s;
}
footer a:hover{
    opacity: 0.8;
}
footer .social{
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 30px;
}


@media (max-width: 1000px) {
    footer{
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
    }
    /* footer .top{
        padding: 30px 0;
        display: block;
        gap: 30px;
    }
    footer .contact{
        margin: 30px 0;
    }
    footer img{
        margin-left: -5px;
        height: 50px;
    } */
}