.contact-title{
    padding: 0;
    padding-top: 150px;
}
.contact-title h1{
    text-align: center;
    font-size: 70px;
    color: white;
}
.location{
    background: black;
    padding-top: 0;
}
.address{
    position: relative;
}
.address img{
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0.5;
}
.address img::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}
.location h2{
    background: var(--blue) ;
    color: white;
    padding: 0 50px;
    font-size: 40px;
    font-weight: 400;
}
.address{
    padding: 80px 50px;
}
.address .info{
    position: relative;
    z-index: 2;
    font-size: 22px;
}
.address .info *{
    margin: 10px 0;
    font-weight: 500;
    color: white;
    transition: .3s;
}
.address .info a:hover{
    /* color: #4B4E53; */
    color: var(--blue);
    text-decoration: underline;

}

@media (max-width: 1000px){
    .location h2{
        padding: 0 30px;
        font-size: 35px;
    }
}