

iframe {
    margin: 0px;
    padding: 0px;
    height: 100vh;
    display: block;
    width: 100%;
    border: none;
    overflow-y: auto;
    overflow-x: hidden;
}

.insights {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 40px;
    column-gap: 4%;
}

@media screen and (max-width: 768px) {
    .insights {
        grid-template-columns: 1fr;
    }
}

.insights-title{
    padding: 0;
    padding-top: 150px;
}
.insights-title h1{
    text-align: center;
    font-size: 70px;
    color: white;
}
.blogs{
    width: 100%;
    background: white;
    padding: 50px 12%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}
.blogs a{
    width: 80%;
    color: black;
}
.blog-container img, .blog-container video {
    height: 350px;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width: 760px) {
    .blog-container img, .blog-container video {
        height: 280px;
    }
}

.blog-container .text h3 {
    font-size: 20px;
    line-height: 1.4em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.blog-container .text .info {
    color: gray;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.blog-container .text .content {
    overflow: hidden; 
    display: -webkit-box; 
    -webkit-line-clamp: 5; 
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; 
    line-height: 1.5em; 
    margin-top: 10px; 
}

.view-more {
    width: 80%;
}

.view-more button {
    background-color: var(--blue);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.view-more button:hover {
    background-color: #59a3ec;
}

@media (max-width: 850px){
    .blogs{
        padding-left: 10px;
        padding-right: 10px;
    }
    .blogs a{
        width: 100%;
    }
    .blog-container{
        gap: 10px;
    }
    .blog-container .text{
        gap: 5px;
    }
    .blog-container .text .content{
        font-size: 14px;
    }
}