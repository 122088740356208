body{
    background: url("../assets/backgroundtile.png") #4B4E53;
}
.services-title{
    padding: 0;
    padding-top: 150px;
}
.services-title h1{
    text-align: center;
    font-size: 70px;
    color: white;
}
.services{
    background: white;
    display: flex;
}
.service{
    padding: 50px;
    width: 50%;
}
.services img{
    width: 50%;
    object-fit: cover;
}
.service h2{
    text-transform: uppercase;
    color: #4B4E53;
    font-size: 2.5em;
    border-top: 20px solid var(--blue) ;
}
.service p{
    font-weight: 300;
    font-size: 18px;
}
.conclusion{
    /* text-align: center; */
    background: white;
    padding: 30px 100px;
    font-size: 18px;
    border-bottom: 10px solid grey;
}
.conclusion p{
    border: 2px solid;
    padding: 30px;
}

@media (max-width: 1000px){
    .services img{
        display: none;
    }
    .service{
        width: 100%;
        padding: 30px;
        padding-top: 0;
    }
    .service h2{
        font-size: 1.7em;
        /* text-align: center; */
    }
    .service p{
        /* text-align: center; */
        margin-top: 5px;
    }
    .conclusion{
        padding: 30px;
        text-align: left;
    }
    .conclusion p{
        padding: 15px;
        /* font-size: 16px; */
    }
}