.hse-title{
    padding: 0;
    padding-top: 150px;
}
.hse-title h1{
    text-align: center;
    font-size: 70px;
    color: white;
}
.hse-title ul{
    font-size: 18px;
    font-weight: 300;
    margin: 10px 0;
    /* margin-left: 30px; */
    list-style: none;
}
.hse-title ul li{
    margin: 10px 0;
}
