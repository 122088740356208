nav{
    justify-self: center;
    background: var(--blue);
    width: 90%;
    z-index: 3;
    font-family: "Bebas Neue", sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .logo{
    width: 120px;
    margin-top: 15px;
  }
  nav ul{
    float: right;
    z-index: 3;
  }
  nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 15px;
  }
  nav ul li a{
    color: white;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 0px 13px;
    border-radius: 3px;
    transition: .3s;
  }
  nav ul li a:hover{
    background: gray;
  }
  .checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 80px;
    margin-right: 40px;
    cursor: pointer;
    display: none;
  }
  #check{
    display: none;
  }

  @media (max-width: 952px) {

    .logo{
        margin-top: 15px;
    }
    nav ul li a{
        font-size: 16px;
    }
}
@media (max-width: 1020px) {
    nav{
        position: fixed;
        top: 0;
        flex-direction: row-reverse;
        padding: 0 10px;
        width: 100%;
    }
    .logo{
        width: 120px;
    }
    .checkbtn{
        display: block;
        margin-right: 0;
    }
    nav ul{
        position: fixed;
        width: 100%;
        height: 0;
        overflow: hidden;
        top: 80px;
        left: 0;
        background: var(--blue);
        /* text-align: center; */
        transition: .3s;

    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 20px;
    }
    nav ul li a{
        font-size: 30px;
    }
    nav ul li a:hover{
        background: none;
        color: lightgray;
    }
    #check:checked ~ ul{
        height: 100vh;
    }
}