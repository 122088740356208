@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Sen:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    /* list-style: none; */
    text-decoration: none;

    --blue: #079ED9
}

html{
  scroll-behavior: smooth;
}
body{
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
}


section{
  padding: 50px;
}
section h1{
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--red);
  font-family: "Bebas Neue", sans-serif;

}
section h1{
  font-weight: 400;
  font-size: 35px;
  text-transform: uppercase;
}
.header{
  position: relative;
}
.header img{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
